import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import slugify from "slugify"
import HubspotForm from "react-hubspot-form"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CustomLink from "../components/customLink"

const InnovationsPage = () => {
  let data = useStaticQuery(graphql`
    query InnovationsPageQuery {
      prismicInnovationsPage {
        data {
          meta_description {
            text
          }
          meta_image {
            url
          }
          page_title {
            text
          }
          title {
            text
          }
          subtitle {
            text
          }
          newsletter_cta_title {
            text
          }
          newsletter_cta_description {
            text
          }
          about_block_title {
            text
          }
          about_block_content {
            html
            text
          }
          body {
            ... on PrismicInnovationsPageBodyPostCard {
              id
              primary {
                title {
                  html
                  text
                }
                subtitle {
                  text
                  html
                }
                post_type
                category
                link {
                  link_type
                  url
                  uid
                  type
                  target
                }
                featured
                contributors {
                  html
                }
                color_theme
                image {
                  url(imgixParams: { w: 2280, q: 85 })
                }
              }
              slice_type
            }
          }
        }
      }
    }
  `)

  if (!data || !data.prismicInnovationsPage) return null
  data = data.prismicInnovationsPage.data

  const featuredPost = data.body.find(
    s =>
      s.__typename === "PrismicInnovationsPageBodyPostCard" &&
      s.primary.featured
  )

  function getFeaturedPost() {
    if (featuredPost) {
      return featuredPost
    } else {
      return data.body.find(
        s => s.__typename === "PrismicInnovationsPageBodyPostCard"
      )
    }
  }

  function getNonFeaturedPosts() {
    if (featuredPost) {
      return data.body.filter(s => !s.primary.featured)
    } else {
      data.body.slice(1, data.body.length)
    }
  }

  return (
    <Layout>
      <SEO
        title={
          data.page_title.text ? data.page_title.text : "VergeSense Innovations"
        }
        description={data.meta_description?.text}
        meta={[{ property: "og:image", content: data.meta_image?.url }]}
        bodyClass="page-innovations"
      />
      <section className="intro">
        <div className="inner">
          <h1 data-sal="slide-down" data-sal-delay="0">
            {data.title.text ? data.title.text : "VergeSense Innovations"}
            <span>NEW</span>
          </h1>
          <h2 data-sal="slide-down" data-sal-delay="300">
            {data.subtitle.text
              ? data.subtitle.text
              : "Where people, place, and tech meet"}
          </h2>
        </div>
      </section>

      <section className="featured-post">
        <div className="inner">
          <div
            className="row featured"
            data-sal="slide-down"
            data-sal-delay="300"
          >
            <PostCard data={getFeaturedPost()} cols={3} featured={true} />
          </div>
        </div>
      </section>

      <section className="posts">
        <div className="inner">
          {getNonFeaturedPosts().map((post, ind) => {
            return <PostCard data={post} ind={ind} cols={1} key={post.id} />
          })}
        </div>

        <div
          className="inner see-all"
          data-sal="slide-down"
          data-sal-delay="300"
        >
          <a href="/resources" className="large">
            Read Our Blog &rarr;
          </a>
        </div>
      </section>

      <section className="about">
        <div className="inner" data-sal="slide-down" data-sal-delay="300">
          <h3>{data.about_block_title.text}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: data.about_block_content.html }}
          ></div>
        </div>
      </section>

      <section className="newsletter">
        <div className="inner">
          <div className="callout" data-sal="slide-up" data-sal-delay="0">
            <h3>
              {data.newsletter_cta_title.text
                ? data.newsletter_cta_title.text
                : "Subscribe Now"}
            </h3>
            <p
              dangerouslySetInnerHTML={{
                __html: data.newsletter_cta_description.text
                  ? data.newsletter_cta_description.text
                  : `The future of work has required bold, creative, and
              forward-thinking builders, and we are confident that our lineup of
              guests is sure to spark inspiration to your own workplace
              transformation journey.`,
              }}
            ></p>
          </div>

          <div className="form" data-sal="slide-up" data-sal-delay="300">
            <HubspotForm
              portalId={process.env.GATSBY_REQUEST_DEMO_HUBSPOT_FORM_PORTAL_ID}
              formId={"2351d00a-d495-432d-b7f2-a2a46a4a7d38"}
              loading={<div>LOADING...</div>}
              css=""
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

const PostCard = ({ data, ind, cols, featured }) => {
  if (!data) return null
  if (ind === 3) cols = 2

  return (
    <CustomLink
      link={data.primary.link}
      className={`post-card theme-${slugify(
        data.primary.color_theme
      ).toLowerCase()} cols-${cols} ${featured ? "featured" : ""}`}
      data-sal="zoom-out"
      data-sal-delay={`${ind * 100}`}
    >
      <div className="post-type">{data.primary.post_type}</div>

      <div
        className="image"
        style={{
          backgroundImage: `url(${data.primary.image.url})`,
        }}
      ></div>

      <div className="content">
        <h3>{data.primary.title.text}</h3>
        <h4>{data.primary.subtitle.text}</h4>

        <div className="meta">
          <div
            className="contributors"
            dangerouslySetInnerHTML={{
              __html: data.primary.contributors?.html,
            }}
          ></div>
          <div className="category">{data.primary.category}</div>
        </div>
      </div>
    </CustomLink>
  )
}

export default InnovationsPage
